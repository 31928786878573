<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2778 8.85506C14.7557 8.42479 15.8333 7.08551 15.8333 5.5C15.8333 3.567 14.2315 2 12.2556 2C10.2796 2 8.67778 3.567 8.67778 5.5C8.67778 7.08551 9.75546 8.42479 11.2333 8.85506V11.0513C9.25137 11.2506 7.37836 12.0266 5.92941 13.2866C5.03554 14.0638 4.33978 14.994 3.88092 16.0156C3.7776 16.0053 3.67276 16 3.56667 16C1.87299 16 0.5 17.3431 0.5 19C0.5 20.6569 1.87299 22 3.56667 22C5.26034 22 6.63333 20.6569 6.63333 19C6.63333 18.1687 6.28767 17.4163 5.72926 16.873C6.065 16.1038 6.58985 15.3882 7.28766 14.7814C8.34902 13.8585 9.7348 13.2546 11.2333 13.0645V16.1707C10.0422 16.5825 9.18889 17.6938 9.18889 19C9.18889 20.6569 10.5619 22 12.2556 22C13.9492 22 15.3222 20.6569 15.3222 19C15.3222 17.6938 14.4689 16.5825 13.2778 16.1707V13.0645C14.7763 13.2546 16.1621 13.8585 17.2234 14.7814C17.8281 15.3072 18.3029 15.9148 18.6374 16.568C17.8675 17.113 17.3667 17.9993 17.3667 19C17.3667 20.6569 18.7397 22 20.4333 22C22.127 22 23.5 20.6569 23.5 19C23.5 17.4064 22.2298 16.103 20.6258 16.0058C20.1669 14.9881 19.4727 14.0614 18.5817 13.2866C17.1328 12.0266 15.2597 11.2506 13.2778 11.0513V8.85506ZM13.7889 5.5C13.7889 6.32843 13.1024 7 12.2556 7C11.4087 7 10.7222 6.32843 10.7222 5.5C10.7222 4.67157 11.4087 4 12.2556 4C13.1024 4 13.7889 4.67157 13.7889 5.5ZM4.58889 19C4.58889 19.5523 4.13122 20 3.56667 20C3.00211 20 2.54444 19.5523 2.54444 19C2.54444 18.4477 3.00211 18 3.56667 18C4.13122 18 4.58889 18.4477 4.58889 19ZM12.2556 20C12.8201 20 13.2778 19.5523 13.2778 19C13.2778 18.4477 12.8201 18 12.2556 18C11.691 18 11.2333 18.4477 11.2333 19C11.2333 19.5523 11.691 20 12.2556 20ZM21.4556 19C21.4556 19.5523 20.9979 20 20.4333 20C19.8688 20 19.4111 19.5523 19.4111 19C19.4111 18.4477 19.8688 18 20.4333 18C20.9979 18 21.4556 18.4477 21.4556 19Z"
    />
  </svg>
</template>
